import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth,
  MapPage, 
  User,
DeliveriesPage,
HomePage} from "./pages";
import { useState,useMemo, useEffect } from 'react';
import { CartContext, FavoritesContext, ProductSelectedContext, UserContext } from './contexts';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import DeliveriesOnMap from './pages/DeliveriesOnMap';
import DeliveryMap from './pages/DeliveryMap';
import { getUserLocation } from './helpers';


const App = ({socket})=>{
  const [cartData,setCartData] = useState([]);
  const cartDataProvider = useMemo(()=>[cartData,setCartData],[cartData,setCartData]);

  const [colorScheme,setColorSheme] = useState("light");
 

  const [userData,setUserData] = useState({loggedIn:false,data:{}});
  const userDataProvider = useMemo(()=>[userData,setUserData],[userData,setUserData]);

  const [favoritesData,setFavoritesData] = useState({loggedIn:false,data:{}});
  const favoritesDataProvider = useMemo(()=>[favoritesData,setFavoritesData],[favoritesData,setFavoritesData]);

  const [productSelectedData,setProductSelectedData] = useState({loggedIn:false,data:{}});
  const productSelectedDataProvider = useMemo(()=>[productSelectedData,setProductSelectedData],[productSelectedData,setProductSelectedData]);
  
  const [coordinates,setCoordinates] = useState({});
  const [errors,setErrors] = useState({
    state:false,
    message:""
  });
  const getUserLocation = ()=>{
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition((position)=>{
        setCoordinates(position.coords);
      
      },(error=>{
        setErrors({
          state:true,
          message:"An error occurred, try again later."
        })
        
      }),
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
     })
    }else{
      setErrors({
          state:true,
          message:"OOPS!! Location services not supported!"
        })
    }
  }

  useEffect(()=>{
    
    const userInfo = window.localStorage.getItem("delivery");
    if(userInfo !== null){
      setUserData(JSON.parse(userInfo));
      
      if(coordinates.latitude){
        socket.emit("create",JSON.stringify({user:{...JSON.parse(userInfo),location:coordinates}}));
      }
    }
  },[coordinates]);

  useEffect(()=>{
    getUserLocation();

    const userInfo = window.localStorage.getItem("delivery");
    if(userInfo !== null){
      setUserData(JSON.parse(userInfo));
      
      //socket.emit("create",JSON.stringify({user:JSON.parse(userInfo)}));
    }

    if(window.matchMedia && window.matchMedia("(prefers-color-scheme:dark)").matches){
      console.log("Dark");
    }else{
      console.log("Light");
    }
     window.matchMedia("(prefers-color-scheme:dark)").addEventListener("change",(e)=>{
    const colorScheme = e.matches?"dark":"light";
    
    setColorSheme(colorScheme);
  })
  },[socket])

  return (
    <HelmetProvider>
    <div style={{
      backgroundColor:"#f5f5f5f5",
      height:"100vh",
      width:"100%",
      position:"relative"
    }} >
      <CookiesProvider defaultSetOptions={{path:"/"}} >
      <CartContext.Provider value={cartDataProvider}>
        <UserContext.Provider value={userDataProvider}>
          <FavoritesContext.Provider value={favoritesDataProvider}>
            <ProductSelectedContext.Provider value={productSelectedDataProvider} >
            <Router>
              <Routes>
                <Route exact path={"/"} element={<HomePage socket={socket} />} />
                <Route exact path={"/map"} element={<DeliveriesOnMap/>} />
                
                <Route exact path={"/recents"} element={<User.Deliveries/>} />
                <Route exact path={"/delivery-map/:id"} element={<DeliveryMap/>} />
                <Route exact path={`/login`} element={<Auth.LoginPage socket={socket}/>}/>
                <Route exact path={`/register`} element={<Auth.RegisterPage/>}/>
                <Route exact path={`/profile`} element={<User.Profile/>}/>
                <Route exact path={`/profile/edit`} element={<User.EditProfile/>}/>
                <Route exact path={`/profile/edit-password`} element={<User.EditPassword/>}/>
                <Route exact path={`/profile/inbox`} element={<User.Inbox/>}/>
                <Route exact path={`/profile/addresses`} element={<User.Addresses/>}/>
                <Route exact path={`/profile/add-address`} element={<User.AddAddress/>}/>
                <Route exact path={`/profile/edit-address/:addressID`} element={<User.EditAddress/>}/>
                
                <Route exact path={`/profile/settings`} element={<User.Settings/>}/>
                <Route exact path={`/profile/finances`} element={<User.Finances.Finances/>}/>
                <Route exact path={`/location/:latitude/:longitude`} element={<MapPage/>}/>
                <Route exact path={`/deliveries`} element={<User.Deliveries/>}/>
                <Route exact path={"/orders"} element={<User.Orders/>} />
                <Route exact path={"/orders/:orderID"} element={<User.OrderDetails/>} />
                <Route exact path={`/deliveries/:deliveryID`} element={<MapPage/>}/>
                <Route exact path={`/delivery-details/:id`} element={<DeliveriesPage.DeliveryDetails/>}/>
              </Routes>
            </Router>
            </ProductSelectedContext.Provider>
          </FavoritesContext.Provider>
        </UserContext.Provider>
      </CartContext.Provider>
      </CookiesProvider>
      
    </div>
    </HelmetProvider>
  )
}

export default App;