import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { io } from 'socket.io-client';

let url = "https://savowarehouse-realtime.onrender.com";
//url = "http://127.0.0.1:5500";
const socket = io(url).connect();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App socket={socket} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
