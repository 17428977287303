import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom';
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet';
import { FaMapPin } from 'react-icons/fa';
import iconMarker from 'leaflet/dist/images/marker-icon.png'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Header, SmallScreenHeader } from '../../components';
import "./addresses.css";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLEMAPSAPIKEY } from '../../utils/APPADATAURLS';
const icon = L.icon({ 
  iconUrl: iconMarker, 
});



const MapPage = () => {
   const params = useParams();
   const latitude = parseFloat(params.latitude);
   const longitude = parseFloat(params.longitude);

   console.log(latitude);
  const {isLoaded} = useJsApiLoader({
    id:"google-map-script",
    googleMapsApiKey:GOOGLEMAPSAPIKEY
  });  

  const [map,setMap] = useState(null);

  return (
    <div className='body' style={{
      width:"100%"
    }} >
        <Header/>
        <div className='addresses-wrapper' >
            <SmallScreenHeader title={"Location"} />
        {/*
        <div className='map' >
            {
            isLoaded?
            <GoogleMap
            mapContainerStyle={{
              width:"100%",
              height:500
            }}

            center={{lat:latitude,lng:longitude}}
            onLoad={(map)=>{
              const bounds = new window.google.maps.LatLngBounds({lat:latitude,lng:longitude});
              map.fitBounds(bounds);
              setMap(map);
            }}
            onUnmount={(map)=>{
                setMap(null);
            }}
            >

            </GoogleMap>
        :
        <MapContainer 
            center={[latitude,longitude]} 
            zoom={13} 
            scrollWheelZoom={false} 
            style={{width:"100%",height:"100%",zIndex:1}}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
            <Marker position={[latitude,longitude]} icon={icon} key={"User"}>
              <Tooltip>My selected location</Tooltip>
              <Popup>My location</Popup>
            </Marker>
            
          </MapContainer>
          } 
        </div>
        */}

        <MapContainer 
            center={[latitude,longitude]} 
            zoom={13} 
            scrollWheelZoom={false} 
            style={{width:"100%",height:"100%",zIndex:1}}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
            <Marker position={[latitude,longitude]} icon={icon} key={"User"}>
              <Tooltip>My selected location</Tooltip>
              <Popup>My location</Popup>
            </Marker>
            
          </MapContainer>
        
        </div>
      
        
    </div>
  )
}

export default MapPage;