import React from 'react';
import "./index.css";
import { Link } from 'react-router-dom';
import { BASEURL } from '../../utils/APPADATAURLS';
import CustomButton from '../CustomButton';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import RateProduct from '../Rating/RateProduct';
import { FaChevronDown } from 'react-icons/fa';

const Order = ({order}) => {
  const [modalShown,setModalShown] = useState(false);
  const [productIndex,setProductIndex] = useState(0);

  const [showDetails,setShowDetails] = useState({
    payment:false,
    items:false
  })
  return (
    <div className='order' >
      <Modal
      show={modalShown}
      centered
      onHide={()=>setModalShown(false)}
      onBackdropClick={()=>setModalShown(false)}
      >
          <Modal.Header closeButton >
            Rate {order.data[productIndex].productName}
          </Modal.Header>
          <Modal.Body>
            <RateProduct product={order.data[productIndex]} setModalShown={setModalShown}/>
          </Modal.Body>
      </Modal>

        <label className='title' >Order : {order.orderNumber}</label>
        <label>Status: {order.status}</label>
        
        <label>No. of items: {order.data.length} items</label>
        <label>Payment : {order.isPaid?"Paid":"Not paid"}</label>

        <label 
        className='title'
        style={{display:showDetails.payment?"none":"flex"}} >
          Payment Details <FaChevronDown onClick={()=>setShowDetails({...showDetails,payment:true})}  /> </label>
        {
          order.isCanceled==1?
          <></>:
          <>
            <div className='payment' style={{display:showDetails.payment?"flex":"none"}} >
              <label className='title' >Payment Details 
                <FaChevronDown onClick={()=>setShowDetails({...showDetails,payment:false})}/> 
              </label>
              <label>REF : {order.transaction.paymentMPesaID}</label>
              <label>Amount : Ksh.{order.transaction.amount}</label>
              <label>Date : {order.transaction.date}</label>
            </div>
          </>
        }
        <label className='title' style={{display:showDetails.items?"none":"flex"}} >Items 
            <FaChevronDown onClick={()=>setShowDetails({...showDetails,items:true})}/> 
        </label>
        <div className='payment'style={{display:showDetails.items?"flex":"none"}} >
          <label className='title' >Items 
            <FaChevronDown onClick={()=>setShowDetails({...showDetails,items:false})}/> 
          </label>
          {
          order.data.map((item,index)=>{
            return <Link
                  to={`/products/${item.productID}`}
                  className='item'
                >
                  <img src={`${BASEURL}/${item.productImage}`} alt={`${item.productName} image`}/>
                  <div>
                    <label>{item.productName} x {item.saleOrderQuantity}</label>
                    <label>Price : Ksh.{item.productPrice}</label>
                    <label>Total : {item.saleOrderTotal}</label>
                    <div
                    style={{
                      position:"absolute",
                      width:"50%",
                      bottom:0,
                      right:0,
                      display:order.isDelivered?"flex":"none"
                    }}
                    onClick={(e)=>{
                      e.stopPropagation();
                      e.preventDefault();
                      setProductIndex(index);
                      setModalShown(true);
                    }}
                  >
                    <CustomButton 
                    title={"Rate"}
                    backgroundColor={"var(--app-color)"}
                    />
                  </div>
                  </div>
                  
                  
                </Link>
          })
        }
        </div>
        
    </div>
  )
}

export default Order;