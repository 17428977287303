import React from 'react';
import "./index.css";
import { FiEdit } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../CustomButton';

const Address = ({address}) => {
  const navigate = useNavigate();
  return (
    <>
    {
      address.latitude?
      <div className='address' >
        <label className='one'>
          Pinned location
        </label>
        <CustomButton
        title={"View On Map"}
        backgroundColor={"var(--app-color)"}
        pressAction={()=>navigate(`/location/${address.latitude}/${address.longitude}`)}
        />
      </div>:
      <div className='address' >
        <label
        className='one'
        >{address.countyName.toUpperCase()}</label>
        <label
        className='two'
        >{address.cityName},</label>
        <label
          className='three'
          >{address.locationName}, </label> 
        <label
        className='two'
        >{address.description},</label>
        <FiEdit
        className='edit-address'
        onClick={()=>navigate(`/profile/edit-address/${address.addressID}`)}
        />
    </div>
    }
    
    </>
  )
}

export default Address;