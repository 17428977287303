import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { APIURL, APPDATAURLS } from "../../../utils/APPADATAURLS";
import { CustomButton, Header, SmallScreenHeader } from "../../../components";
import {
  CategoriesLoading,
  HomeCategoriesLoading,
} from "../../../components/Loading";
import { LoginPage } from "../../Auth";
import { FaPhone } from "react-icons/fa";
import { Toast } from "react-bootstrap";
import { FiMapPin } from "react-icons/fi";
import { UserContext } from "../../../contexts";

const OrderDetails = () => {
  const { orderID } = useParams();
  const navigate = useNavigate();

  const [delivery, setDelivery] = useState({
    error: true,
    loading: true,
    message: "",
    results: null,
  });
  const [userData, setUserData] = useContext(UserContext);

  const fetchDelivery = async () => {
    setDelivery({
      loading: true,
      error: false,
      message: "",
      results: null,
    });
    

    fetch(
      `${APPDATAURLS.availableOrder}&orderID=${orderID}`,
      {
        method: "GET",
      }
    )
      .then((req) => req.json())
      .then((response) => {
        setDelivery({
          loading: false,
          error: response.error,
          message: response.message,
          results: response.data,
        });
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
        setDelivery({
          loading: false,
          error: true,
          message: "An error occurred! Try again later",
          results: null,
        });
      });
  };

  const [picked, setPicked] = useState({
    loading: false,
    error: false,
    message: "",
    results: null,
  });

  const markAsPicked = (id) => {
    
    setPicked({
      loading: true,
      error: false,
      message: "",
      results: null,
    });

    let formData = new FormData();
    formData.append("mark-as-picked", "mark");
    formData.append("saleOrderID", id);

    fetch(APIURL, {
      method: "POST",
      body: formData,
    })
      .then((req) => req.json())
      .then((response) => {
        setPicked({
          loading: false,
          error: response.error,
          message: response.message,
          results: response.data,
        });
        fetchDelivery();
      })
      .catch((err) => {
        console.log(err);
        setPicked({
          loading: false,
          error: true,
          message: "An error occurred! Try again later",
          results: null,
        });
      });
  };

  const [take, setTake] = useState({
    loading: false,
    error: false,
    message: "",
    results: null,
  });
  const markAsTake = (id) => {
    setTake({
      loading: true,
      error: false,
      message: "",
      results: null,
    });
    let formData = new FormData();
    formData.append("mark-as-taken", "mark");
    formData.append("orderID", orderID);
    formData.append("delivery_guy", userData?.data?.id);
    

    fetch(APIURL, {
      method: "POST",
      body: formData,
    })
      .then((req) => req.json())
      .then((response) => {
        setTake({
          loading: false,
          error: response.error,
          message: response.message,
          results: response.data,
        });
        fetchDelivery();
        if(!response.error){
          navigate("/deliveries");
        }
      })
      .catch((err) => {
        console.log(err);
        setTake({
          loading: false,
          error: true,
          message: "Something went wrong! Try again later",
          results: null,
        });
      });
  };

  useEffect(() => {
    fetchDelivery();
  }, []);

  const [isBig, setIsBig] = useState(true);
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 800) {
        setIsBig(true);
      } else {
        setIsBig(false);
      }
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth > 800) {
      setIsBig(true);
    } else {
      setIsBig(false);
    }
  }, [window.innerWidth]);
  return (
    <>
      {userData.loggedIn ? (
        <div className="body">
          <Toast
            show={take.error || picked.error}
            onClose={() => {
              setTake({ ...take, error: false, message: "" });
              setPicked({ ...picked, error: false, message: "" });
            }}
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              zIndex: 300,
              backgroundColor: "red",
            }}
          >
            <Toast.Header closeButton />
            <Toast.Body>{take.message || picked.message}</Toast.Body>
          </Toast>
          <Header />
          <div className="body-wrapper">
            <SmallScreenHeader titleCentered={true} title={"Details"} />
            <div className="delivery-details">
              <div
                style={{
                  width: "100%",
                  marginTop: 75,
                  height: "calc(100vh-75px)",
                  position: "relative",
                  padding: isBig ? "0" : "1%",
                }}
              >
                {delivery.loading ? (
                  <HomeCategoriesLoading />
                ) : (
                  <>
                    <div className="detials-title">
                      <h3>Order id : {delivery.results?.orderID}</h3>
                      <p style={{
                        color:delivery.results?.isTaken?"red":"green"
                      }}>
                        Status : {delivery.results?.isTaken?"Unavailable":"Available"}
                        
                      </p>
                    </div>
                    <p>
                      Delivery amount :{" "}
                      <b>Ksh.{delivery.results?.delivery_cost ?? 0}</b>
                    </p>
                    <div className="details-customer">
                      <h4
                        style={{
                          textAlign: "center",
                          textDecorationLine: "underline",
                        }}
                      >
                        Customer
                      </h4>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <label>
                          {delivery.results?.firstName}{" "}
                          {delivery.results?.lastName}
                        </label>
                        <a
                          href={`tel:${delivery.results?.phoneNumber}`}
                          style={{
                            textDecoration: "none",
                            display: "flex",
                            flexDirection: "row",
                            gap: 10,
                            height: 40,
                            alignItems: "center",
                            fontWeight: "bold",
                            color: "var(--app-color)",
                          }}
                        >
                          Call {delivery.results?.lastName}<FaPhone />
                        </a>
                      </div>
                      <h4>Deliver at:</h4>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label
                          style={{
                            marginBottom: 10,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          {" "}
                          <FiMapPin />
                          {delivery.results?.google_data
                            ? JSON.parse(delivery.results?.google_data)
                                .results[0].formatted_address
                            : "Pinned locaton"}
                        </label>
                      </div>
                    </div>
                    <div
                      className="details-products"
                      style={{
                        marginBottom: 40,
                      }}
                    >
                      <h4
                        style={{
                          textAlign: "center",
                          textDecorationLine: "underline",
                        }}
                      >
                        Products
                      </h4>
                      {delivery.results?.data?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>
                              <b>Company:</b> {item.companyName}
                            </label>
                            <label>
                              <b>Product:</b> {item.productName}
                            </label>
                            <label>
                              <b>Total:</b> {item.saleOrderQuantity}
                            </label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 10,
                              }}
                            >
                              <a
                                href={`tel:${item.phoneNumber}`}
                                style={{
                                  textDecoration: "none",
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 10,
                                  height: 40,
                                  alignItems: "center",
                                  fontWeight: "bold",
                                  color: "var(--app-color)",
                                }}
                              >
                                Call
                                <FaPhone />
                              </a>
                              {!delivery.results?.isDelivered &&
                              !item?.isPicked ? (
                                <CustomButton
                                  title={
                                    picked.loading
                                      ? "Loading..."
                                      : "Mark as picked"
                                  }
                                  backgroundColor={"var(--app-color)"}
                                  pressAction={() =>
                                    markAsPicked(item?.saleOrderID)
                                  }
                                  disabled={picked.loading ? true : false}
                                />
                              ) : (
                                <></>
                              )}
                              {/* {
                                            item.latitude?
                                            <CustomButton
                                                title={"View on map"}
                                                backgroundColor={"var(--app-color)"}
                                                pressAction={()=>navigate(`/delivery-map/${id}`)}
                                                
                                            />:<></>
                                        } */}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {!delivery.results?.isDelivered && !delivery.results?.isTaken? (
                      <div
                        style={{
                          position: "fixed",
                          bottom: 0,
                          width: isBig ? "90%" : "97%",
                        }}
                      >
                        <CustomButton
                          title={
                            take.loading
                              ? "Loading...."
                              : "Take this Order"
                          }
                          backgroundColor={"var(--app-color)"}
                          pressAction={() =>
                            markAsTake(delivery.results?.orderID)
                          }
                          disabled={take.loading ? true : false}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoginPage />
      )}
    </>
  );
};

export default OrderDetails;
