import React,{useState,useEffect} from 'react'
import { BottomTabs, CustomButton, Footer, Header, SmallScreenHeader } from '../../components';
import { FaChevronRight, FaEdit, FaPen } from 'react-icons/fa';
import { Button, Modal } from 'react-bootstrap';
import "./profile.css";
import { useContext } from 'react';
import { UserContext } from '../../contexts';
import { Auth } from '..';
import { APIURL, APPDATAURLS, BASEURL } from '../../utils/APPADATAURLS';
import { Link } from 'react-router-dom';

function Profile() {
  const [activeTab,setActiveTab] = useState(0);
  const [showEditModal,setShowEditModal] = useState(0);
  const [showEditPasswordModal,setShowEditPasswordModal] = useState(0);
  const [userData,setUserData] = useContext(UserContext);
  const [image,setImage] = useState({
    encodedImage:null
  });

  const [orders,setOrders] = useState([]);
    const [addresses,setAddresses] = useState([]);

  const [data,setData] = useState({
      orders:[],
      addresses:[]
  });

  const [deliveries,setDeliveries] = useState(
    {
        loading:false,
        error:false,
        message:"",
        results:[]
    });
  
  const [user,setUser] = useState({
    name:userData.data.name,
    email:userData.data.email,
    phoneNumber:userData.data.phoneNumber,
    oldPassword:"",
    newPassword:""
  });

    
    const logout = ()=>{
        setUserData({loggedIn:false,data:{}});
        localStorage.removeItem("delivery");
    }


    

    const handlePickImage = async (e)=>{
      setImage({encodedImage:await toBase64(e.target.files[0])})  
    }
    const [upload,setUpload] = useState({
      loading:false,
      error:false,
      message:""
    });
    const uploadImage = async ()=>{
      setUpload({
        loading:true,
        message:"",
        error:false
      });
      let formData = new FormData();
      formData.append("set-delivery-profile-image","set");
      formData.append("userID",userData.data.id);
      formData.append("profile-image",image.encodedImage);
      const token = null;
      fetch(APIURL,{
          method:"POST",
          body:formData,
          /*headers:{
            "Authorization":`Bearer ${token}`
          },
          credentials:"include"*/
      }).
      then(req=>req.json()).
      then(response=>{
          setImage({
            encodedImage:null
          });
          setUpload({
            loading:false,
            message:response.message,
            error:response.error
          });
          setUserData({loggedIn:true,data:response.data.info});
          localStorage.removeItem("user");
          localStorage.setItem("user",JSON.stringify({loggedIn:true,data:response.data.info}));
      }).catch(e=>{
          console.log(e)
          setUpload({
            loading:false,
            message:"Profile image not uploaded. Try again later.",
            error:true
          });
      })
    }
    const toBase64 = (file) =>new Promise((resolve,reject)=>{
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function(){
              //console.log(reader.result);
              //out = reader.result;
              resolve(reader.result);
          }

          reader.onerror = function(err){
              reject(err)
          }
      })


    const updateProfile = ()=>{
      let formData = new FormData();
      formData.append("delivery-update-profile",user.name);
      formData.append("name",user.name);
      formData.append("email",user.email);
      formData.append("phoneNumber",user.phoneNumber);
      formData.append("id",userData.data.id);
      fetch(APIURL,{
        method:"POST",
        body:formData
      }).
      then(req=>req.json()).
      then(res=>{
        console.log(res);
        localStorage.removeItem("delivery");
        let data = {loggedIn:true,data:res.data}; 
        setUserData(data);
        localStorage.setItem("delivery",JSON.stringify(data));
        setShowEditModal(false);
      }).catch(e=>{
        console.log(e);
      })
    }

    const updatePassword = ()=>{
      let formData = new FormData();
      formData.append("delivery-update-password",user.name);
      formData.append("oldPassword",user.oldPassword);
      formData.append("newPassword",user.newPassword);
      formData.append("id",userData.data.id);
      fetch(APIURL,{
        method:"POST",
        body:formData
      }).
      then(req=>req.json()).
      then(res=>{
        console.log(res);
        setShowEditPasswordModal(false);
      }).catch(e=>{
        console.log(e);
      })
    }
      
    const fetchDeliveries = ()=>{
        fetch(`${APPDATAURLS.getDeliveryGuyDeliveries}${userData.data.id}`,{
            method:"GET"
        })
        .then(req=>req.json())
        .then(response=>{
            setDeliveries({...deliveries,results:response.data})
        }).catch(err=>{
            console.log(err);
        });
    }

      useEffect(()=>{
        window.scroll(0,0);
        fetchDeliveries();

          if(localStorage.getItem("delivery"))
          {
          let u = JSON.parse(localStorage.getItem("delivery"));
          setUser({
            name:u.data.name,
            email:u.data.email,
            phoneNumber:u.data.phoneNumber
          });
        }

      },[userData.loggedIn])
  return (
    <>
    {
      userData.loggedIn?
      <div
    className='body'
    >
      <Header/>

      <Modal
      show={showEditModal}
      onBackdropClick={()=>setShowEditModal(false)}
      size='xl'
      onHide={()=>setShowEditModal(false)}
      >
        <Modal.Header closeButton >
          <Modal.Title>
            Edit profile info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
        style={{
          display:"flex",
          flexDirection:"column"
        }}
        >
          <label>Name</label>
          <input value={user.name} onChange={(e)=>setUser({...user,name:e.target.value})} />
          <label>Email</label>
          <input value={user.email} onChange={(e)=>setUser({...user,email:e.target.value})}/>
          <label>Phone number</label>
          <input value={user.phoneNumber} onChange={(e)=>setUser({...user,phoneNumber:e.target.value})}/>
        </Modal.Body>
        <Modal.Footer>
          <Button
          variant='success'
          onClick={()=>{
            console.log(user);
            updateProfile();  
          }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
      show={showEditPasswordModal}
      onBackdropClick={()=>setShowEditPasswordModal(false)}
      size='xl'
      onHide={()=>setShowEditPasswordModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
        style={{
          display:"flex",
          flexDirection:"column"
        }}
        >

          <label>Old password</label>
          <input value={user.oldPassword} onChange={(e)=>setUser({...user,oldPassword:e.target.value})}/>
          <label>New password</label>
          <input value={user.newPassword} onChange={(e)=>setUser({...user,newPassword:e.target.value})}/>
        </Modal.Body>
        <Modal.Footer>
          <Button
          variant='success'
          onClick={()=>{
            console.log(user);
            updatePassword();  
          }}
          >
            Update password
          </Button>
        </Modal.Footer>
      </Modal>
      
      <div
        className='body-wrapper'
      >
        <SmallScreenHeader backButtonShown={false} title={"My Profile"} titleCentered={true} />
        <div
        style={{
         position:"relative"
        }}
        className='user-profile'
        >
            <div className='profile-image'>
              <img 
              src={image.encodedImage?image.encodedImage:`${BASEURL}/${userData.data.image}`} 
              alt='profile image' />
              <input type='file' onChange={handlePickImage}/>
            </div>
            <div
              className='name-email'
            >
              <label className='name' >{userData.data.name}</label>
              <label className='email'>{userData.data.email}</label>
            </div>
            <FaEdit
            style={{
              color:"var(--app-color)",
              position:"absolute",
              top:0,
              right:20
            }}
            onClick={()=>setShowEditModal(true)}
            />
        </div>
        <div
        style={{
          width:"98%",
          margin:"1%",
          display:image.encodedImage?"flex":"none",
          flexDirection:"row",
          gap:10
        }}
        >
          <CustomButton 
          disabled={upload.loading}
          title={upload.loading?"Uploading...":"Cancel"} 
          pressAction={()=>{
            setImage({...image,encodedImage:null})
          }} 
          display={image.encodedImage?"flex":"none"} 
          backgroundColor={"var(--app-color)"}
          width={"30%"}
          
          />
          <CustomButton 
          disabled={upload.loading}
          title={upload.loading?"Uploading...":"Upload profile picture"} 
          pressAction={uploadImage} 
          display={image.encodedImage?"flex":"none"} 
          backgroundColor={"var(--app-color)"}

          />
        </div>
        

        <div className='profile-info' >
          {/*<Link
            to={"/profile/orders"}
            className='profile-buttons'
          >
            <div>
              <label>Orders</label>
              <label>
                {orders.length>0?`You have ${orders.length} orders`:"You have no orders"}
              </label>
            </div>
            <FaChevronRight/>
          </Link>*/}
          {
            !userData.data.deliveryGuyID?
             <Link
            to={"/deliveries"}
            className='profile-buttons'
          >
            <div>
              <label>My deliveries</label>
              <label>
                {deliveries.results.length>0?
                `You have ${deliveries.results.length} pending deliver${deliveries.results.length>1?"ies":"y"}.`:
                "You have no pending deliveries."}
              </label>
            </div>
            <FaChevronRight/>
          </Link>:
          <></>
          }
         

          {/*<Link
            to={"/profile/addresses"}
            className='profile-buttons'
          >
            <div>
              <label  >Shipping addresses</label>
              <label >
                {addresses.length>0?`You have ${addresses.length} addresses`:"No addresses"}
              </label>
            </div>
            <FaChevronRight/>
          </Link>*/}
          <Link
            to={"/profile/finances"}
            className='profile-buttons'
          >
            <div>
              <label>Finances</label>
              <label>My money</label>
            </div>
            <FaChevronRight/>
          </Link>
          <Link
            to={"/profile/addresses"}
            className='profile-buttons'
          >
            <div>
              <label>Promo codes</label>
              <label>No codes</label>
            </div>
            <FaChevronRight/>
          </Link>
          <Link
            to={"/profile/settings"}
            className='profile-buttons'
          >
            <div>
              <label>Settings</label>
              <label></label>
            </div>
            <FaChevronRight/>
          </Link>
        </div>
      
        <div
        style={{
          marginBottom:70,
          display:"flex",
          gap:10,
          flexDirection:"column"
        }}
        >
          <CustomButton
          pressAction={()=>setShowEditPasswordModal(true)}
          title={"Update password"}
          backgroundColor={"var(--app-color)"}
          width={"100%"}
          borderRadius={20}
          color={"#ffffff"}
          
          />


          <CustomButton
          pressAction={logout}
          title={"Log out"}
          backgroundColor={"var(--app-danger)"}
          width={"100%"}
          borderRadius={20}
          color={"#ffffff"}
          />
        </div>
        
      </div>
      <BottomTabs/>
      <Footer/>
    </div>:
    <Auth.LoginPage/>
    }
    
    </>
  )
}

export default Profile;