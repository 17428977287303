import React, { useEffect, useState,useContext } from 'react'
import { BottomTabs, CustomButton, Delivery, Header, SmallScreenHeader } from '../../../components';
import "./index.css";
import { UserContext } from '../../../contexts';
import { LoginPage } from '../../Auth';
import { APPDATAURLS } from '../../../utils/APPADATAURLS';
import { HomeCategoriesLoading } from '../../../components/Loading';
import { useUserLocation } from '../../../hooks';
import Order from './Order';

const Deliveries = () => {
    

    const [activeTab,setActiveTab] = useState(0);
    const [userData,setUserData] = useContext(UserContext);
    const [deliveries,setDeliveries] = useState(
        {
            loading:true,
            error:false,
            message:"",
            results:null
        }
    )

    const userLocation = useUserLocation();

    const fetchDeliveries = ()=>{
        fetch(`${APPDATAURLS.availableOrders}&latitude=${userLocation.coordinates.latitude}&longitude=${userLocation.coordinates.longitude}`,{
            method:"GET"
        })
        .then(req=>req.json())
        .then(response=>{
            setDeliveries({loading:false,error:response.error,message:response.message,results:response.data})
        }).catch(err=>{
            console.log(err);
        });
    }
    useEffect(()=>{
        if(userLocation.coordinates.latitude){
            fetchDeliveries();
        }
    },[userData.loggedIn,userLocation])
  return (
    <>
    {
        userData.loggedIn?
    <div className='body' >
        <Header/>
        <div className='body-wrapper' >
        <SmallScreenHeader backButtonShown={false} title={"Available Orders"} />
        <div className='btns'>
            {/* <div className='delivery-btn'>
                <CustomButton
                pressAction={()=>setActiveTab(0)}
                title={"All deliveries"}
                width={"100%"}
                backgroundColor={activeTab==0?"var(--app-color)":"#f5f5f5"}
                color={activeTab==0?"#ffffff":"#000000"}
                />
            </div>
            
             <div className='delivery-btn'>
                <CustomButton
                pressAction={()=>setActiveTab(1)}
                title={"Pending"}
                width={"100%"}
                backgroundColor={activeTab==1?"var(--app-color)":"#f5f5f5"}
                color={activeTab==1?"#ffffff":"#000000"}
                />
            </div>

             <div className='delivery-btn'>
                <CustomButton
                pressAction={()=>setActiveTab(2)}
                title={"Delievered"}
                width={"100%"}
                backgroundColor={activeTab==2?"var(--app-color)":"#f5f5f5"}
                color={activeTab==2?"#ffffff":"#000000"}
                />
            </div>

            <div className='delivery-btn'>
                <CustomButton
                pressAction={()=>setActiveTab(3)}
                title={"Paid"}
                width={"100%"}
                backgroundColor={activeTab==3?"var(--app-color)":"#f5f5f5"}
                color={activeTab==3?"#ffffff":"#000000"}
                />
            </div>

            <div className='delivery-btn'>
                <CustomButton
                pressAction={()=>setActiveTab(4)}
                title={"Unpaid"}
                width={"100%"}
                backgroundColor={activeTab==4?"var(--app-color)":"#f5f5f5"}
                color={activeTab==4?"#ffffff":"#000000"}
                />
            </div> */}

        </div>
        <div className='deliveries' >

            {
            deliveries.error?
            <p>{deliveries.message}</p>:
            deliveries.loading?
            <HomeCategoriesLoading/>:
            deliveries.results?.length<0?
            <p>No orders found</p>:
            deliveries.results?
                activeTab==0?deliveries.results.map((item,index)=>{
                    return <Order key={index} item={item} />
                }):
                activeTab==1?
                deliveries.results.filter(i=>i.isDelivered==0).map((item,index)=>{
                    return <Order key={index} item={item} />
                }):activeTab==2?
                deliveries.results.filter(i=>i.isDelivered==1).map((item,index)=>{
                    return <Order key={index} item={item} />
                }):<></>:<></>
            }
        </div>
        </div>
            <BottomTabs/>
    </div>:
    <LoginPage/>
        }
        </>
  )
}

export default Deliveries;