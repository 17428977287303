import React from 'react'
import CustomButton from '../CustomButton'
import { useState } from 'react'
import { FiStar } from 'react-icons/fi';
import "./index.css";
import { useContext } from 'react';
import { UserContext } from '../../contexts';
import { APIURL } from '../../utils/APPADATAURLS';

const RateProduct = ({product,setModalShown}) => {
    const [rating,setRating] = useState({
        rating:0,
        review:""
    });

    const [userData,setUserData] = useContext(UserContext);
    const [data,setData] = useState({
        loading:false,
        error:false,
        message:"",
        results:[],
    });
    const rate = ()=>{
        let formData = new FormData();
        formData.append("add-rating","add");
        formData.append("stars",rating.rating);
        formData.append("review",rating.review);
        formData.append("productID",product.productID);
        formData.append("userID",userData.data.userID);
        setData({
            loading:true,
            error:false,
            message:"",
            results:[]
        });
        fetch(APIURL,{
            method:"POST",
            body:formData
        }).then(req=>req.json()).then(response=>{
            setData({
            loading:false,
            error:response.error,
            message:response.message,
            results:[]
        });

        if(!response.error){
            setTimeout(()=>{
                setModalShown(false);
            },2000)
        }
        }).catch(e=>{
            setData({
            loading:false,
            error:true,
            message:"Unable to rate product, try again later.",
            results:[]
        });
        })
    }
  return (
    <div
    className='rate-product'
    >
        {
            data.error?
            <label className='text-danger' >{data.message}</label>:
            data.loading?
            <label className='' >Loading...</label>:
            <label className='text-success' >{data.message}</label>
        }
        <div>
            {
                [1,2,3,4,5].map((item,index)=>{
                    return <FiStar 
                        color={item<=rating.rating?'var(--app-color)':'#b9b4b4'}  
                        fill={item<=rating.rating?'var(--app-color)':'#b9b4b4'} 
                        size={30} 
                        onClick={()=>setRating({...rating,rating:index+1})}
                        />
                })
            }
        </div>
        

        <label>Review</label>
        <textarea
        placeholder='Type something here...'
        onChange={(e)=>setRating({...rating,review:e.target.value})}
        >

        </textarea>
        <CustomButton
        pressAction={rate}
        title={"Rate"}
        backgroundColor={"var(--app-color)"}
        disabled={rating.rating==0?true:false}
        />
    </div>
  )
}

export default RateProduct