import React from 'react';
import {Link,useNavigate} from "react-router-dom";
import { CustomButton } from '../../../components';
import "./index.css";
import { FiEye, FiEyeOff, FiSend } from 'react-icons/fi';
import { APIURL } from '../../../utils/APPADATAURLS';
import { useState } from 'react';
const RegisterPage = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
  });
  
  const [data,setData] = useState({
    results:[],
    error:false,
    loading:false,
    message:"",
  });
  const handleRegister = ()=>{
    let formData = new FormData();
    if (userDetails.firstName.length < 1) {
      setData({
        error:true,
        loading:false,
        message:"First name is required!",
        data:[]
      });
      return false;
    }

    if (userDetails.lastName.length < 1) {
      setData({
        error:true,
        loading:false,
        message:"Last name is required!",
        data:[]
      });
      return false;
    }

    if (userDetails.email.length < 1) {
      setData({
        error:true,
        loading:false,
        message:"Email is required!",
        data:[]
      });
      return false;
    }

    if (userDetails.phoneNumber.length < 1) {
      setData({
        error:true,
        loading:false,
        message:"Phone number is required!",
        data:[]
      });
      return false;
    }

    if (userDetails.password.length < 1) {
      setData({
        error:true,
        loading:false,
        message:"Password is required!",
        data:[]
      });
      return false;
    }


    setData({
        error:false,
        loading:true,
        message:"",
        data:[]
      });
    formData.append('register', 'register');
    formData.append('firstName', userDetails.firstName);
    formData.append('lastName', userDetails.lastName);
    formData.append('phoneNumber', userDetails.phoneNumber);
    formData.append('email', userDetails.email);
    formData.append('password', userDetails.password);
    fetch(APIURL, {
      method: 'post',
      body: formData,
    })
      .then(req => req.json())
      .then(res => {
        setData({
        error:res.error,
        loading:false,
        message:res.message,
        data:[]
      });
      if(!res.error){
        navigate("/login");
      }
      })
      .catch(e => {
        setData({
        error:true,
        loading:false,
        message:e.message,
        data:[]
      });
      });
  }
  
  const [isPassword,setIsPassword] = useState(true);
  return (
    <div
    className="body"
    >
      <div
      className="login_body"
      >
        <img src={"/assets/images/savo_logo.jpg"}/>

        <div className="container" >
          <div
          className="login_credentials"
          >
            <Link
            to={"/"}
            className="website_name"
            >savowarehouse.
              <p style={{color:"var(--app-color)"}} >com</p>
            </Link>
            <p className="login_into_account" >Create your Account.</p>
            <div
              className="credentials_container"
            >
              {
                data.error?
                <label
                style={{
                  color:"red"
                }}
                >{data.message}</label>:
                null
              }
              <label
              style={{
                marginTop:5
              }}
              >First name</label>
              <input 
                type="text"
                onChange={(e)=>setUserDetails({...userDetails,firstName:e.target.value})}
                placeholder="First name..."/>
              <label>Last name</label>
              <input 
                type="text"
                onChange={(e)=>setUserDetails({...userDetails,lastName:e.target.value})}
                placeholder="Last name..."/>
              <label>Email</label>
              <input 
                type="email"
                onChange={(e)=>setUserDetails({...userDetails,email:e.target.value})}
                required placeholder="Email..."/>
              <label>Phone number</label>
              <input 
                type="text"
                onChange={(e)=>setUserDetails({...userDetails,phoneNumber:e.target.value})}
                placeholder="Phone number..."/>
              <label>Password</label>
              <div 
                style={{
                  position:"relative",
                  width:"100%"
                }}
                
                >
                  <input 
                  type={isPassword?"password":"text"}
                  onChange={(e)=>setUserDetails({...userDetails,password:e.target.value})} 
                  placeholder="Password..."/>
                  {
                    isPassword?
                    <FiEyeOff onClick={()=>setIsPassword(false)} size={25} style={{
                      position:"absolute",
                      right:10,
                      top:10
                    }} />:
                    <FiEye onClick={()=>setIsPassword(true)} size={25} style={{
                      position:"absolute",
                      right:10,
                      top:10
                    }} />
                  }

                </div>
              <label>Confirm password</label>
              <input type="password" placeholder="Confirm password..."/>
              <div className="login_register" >
                <div className='login-btn' >
                  <CustomButton 
                  pressAction={handleRegister}
                  title={"Sign up"} 
                  borderRadius={20}
                  backgroundColor={"var(--app-color)"}
                  accessoryRight={<FiSend style={{marginLeft:10}} />}
                  />
                </div>
                
                <p>
                  Have an account?
                  <Link className="link" to="/login" >Sign in here</Link>
                </p>
              </div>
              
              
            </div>

            
          </div>
          
        </div>

      </div>

    </div>
  )
}

export default RegisterPage