import React,{ useEffect, useRef,useState } from 'react'
import "./index.css";

import camaro from "../../assets/images/camaro.jpg";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import CustomButton from '../CustomButton';
import { APPDATAURLS, BASEURL } from '../../utils/APPADATAURLS';
import { FiLoader } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';

const Banner = ({marginTop=0}) => {
  const bannerRef = useRef(0);
  const imageRef = useRef(0);
  const navigate = useNavigate();
  const [visibleArrow,setVisibleArrow] = useState({
    right:false,
    left:true,
    scrolledWidth:0
  });
  const [adverts,setAdverts] = useState({
    data:[],
    loading:false,
    error:false,
    message:""
  });
const [imageLoaded,setImageLoaded] = useState(false);


  const fetchAdverts = ()=>{
    setAdverts({
      data:[],
      loading:true,
      error:false,
      message:""
    });
    fetch(APPDATAURLS.adverts,{
      method:"get"
    }).then(req=>req.json()).then(response=>{
      setAdverts({
      data:response.data,
      loading:false,
      error:response.error,
      message:response.message
    });
    }).catch(e=>{
      console.log(e);
      setAdverts({
        data:[],
        loading:false,
        error:true,
        message:"An error occurred. Try again later"
    });
    })
  }
  
  let c = 0;
  useEffect(()=>{
    if(imageRef?.current){
      let dims = imageRef.current.getBoundingClientRect();
        const interval = setInterval(()=>{
          if(c >= adverts.data.length){
            
            c = 0;
          }
          if(bannerRef?.current){
            bannerRef.current.scrollLeft = (dims.width*c);
            c++;
          }
          
        },3000)

        //clearInterval(interval)
    }
    },[adverts])

useEffect(()=>{
  //fetchAdverts();
},[])
  return (
    <div 
      className='banner'
      style={{
        marginTop
      }}
     >
      <div
        className='banner-move-button banner-prev'
      >
        <FaChevronLeft 
          size={30} 
          fill={"#ffffff"}

          onClick={()=>{
            const dimensions = imageRef.current.getBoundingClientRect();
            bannerRef.current.scrollLeft += dimensions.width;
            setVisibleArrow({...visibleArrow,scrolledWidth:visibleArrow.scrolledWidth+dimensions.width});
          }}
          />
      </div>
      <div 
        className='banner-image-description'
        ref={bannerRef}
      >
        {
          adverts.error?
          <div className='banner-image-banner' >
            <Lottie className='banner-image' loop animationData={require("../../assets/lottie_animations/no-internet.json")}/>
          </div>:
          adverts.loading?
          <div className='banner-image-banner' >
            <Lottie className='banner-image' loop animationData={require("../../assets/lottie_animations/progress.json")}/>
          </div>:
          adverts.data.length>0?
          adverts.data.map((product,index)=>{
            return(
              <div 
                className='banner-image-banner' 
                ref={imageRef} 
                key={index}
                
                >
                {
                  product.showProductVideo==1?
                  <video
                    controls
                    className='banner-image'
                    onLoad={(e)=>{
                  setImageLoaded(true)}}
                onError={(e)=>{
                  setImageLoaded(false)}}
                    style={{
                    }}
                    src={`${BASEURL}/${product.productVideo}`}
                  />:
                  <img
                    className='banner-image'
                    onLoad={(e)=>{
                  setImageLoaded(true)}}
                onError={(e)=>{
                  setImageLoaded(false)}}
                    style={{
                    }}
                    src={`${BASEURL}/${product.productImage}`}
                  />
                }
                

                  {/*<img
                    className='default-banner-image'
                    style={{
                      display:imageLoaded?"none":"flex"
                    }}
                    src={`/assets/images/savo_logo.jpg`}
                  />*/}
                  <div
                  style={{
                    position:"absolute",
                    bottom:0,
                    width:"100%",
                    padding:"5%",
                    backgroundColor:"#99999999"
                  }}
                  >
                    <p style={{
                      color:"#ffffff",
                      fontSize:20,
                      fontWeight:"bolder"
                    }} >{product.productName}</p>
                    <CustomButton
                      pressAction={()=>navigate(`/products/${product.productID}`)}
                      width={100}
                      title={"Check"}
                      backgroundColor={"var(--app-color)"}
                      borderRadius={20}
                      />
                    
                  </div>
              </div>
            )
          }):<div
          className='data-holder'
          >
            Their is nothing here.
          </div>
        }
      </div>
      
      <div
        className='banner-move-button banner-next '

      >
        <FaChevronRight 
          size={30} 
          fill={"#ffffff"}
          onClick={()=>{
            const dimensions = imageRef.current.getBoundingClientRect();
            bannerRef.current.scrollLeft -= dimensions.width;
            setVisibleArrow({...visibleArrow,scrolledWidth:visibleArrow.scrolledWidth-dimensions.width});
          }}
          />
      </div>
     </div>
  )
}

export default Banner