import React,{useContext, useState,useEffect} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {FaSearch,FaUser,FaInbox,FaBook,FaShoppingCart, FaHeart,FaShoppingBag, FaHome, FaMap, FaBus} from "react-icons/fa";
import "./index.css";
import SmallScreenSidebar from "../SmallScreenSideBar";
import { CartContext } from "../../contexts/cartContext";
import { FiLogOut,FiMenu } from "react-icons/fi";
import { UserContext } from "../../contexts/UserContext";
import { Button, Modal } from "react-bootstrap";
import SellerRegistraion from "../SellerRegistration";
import { HomeCategoriesLoading } from "../Loading";
import { APIURL } from "../../utils/APPADATAURLS";
import Search from "../Search";
const Header = ()=>{
    const navigate = useNavigate();
    const [isVisible,setIsVisible] = useState(false);
    const [showModal,setShowModal] = useState(false);
    const [searchText,setSearchText] = useState("");
    const [data,setData] = useState({
        loading:false,
        results:[],
        error:false,
        message:""
    });
    const [loggedIn,setLoggedIn] = useState(true);

    const [cartData,setCartData] = useContext(CartContext);
    const total = cartData.map(item=>Number(item.quantity)).reduce((prev,curr)=>prev+curr,0);

    const [userData,setUserData] = useContext(UserContext);
    
    const logout = ()=>{
        setUserData({loggedIn:false,data:{}});
        localStorage.removeItem("delivery");
    }

    const goToSellerDashboard = ()=>{
        navigate("/shop/dashboard/1");
    }

    const [activeTab,setActiveTab] = useState(null);
    const location = useLocation();
    useEffect(()=>{
        setActiveTab(location.pathname)
    },[location]);

    const search = (text)=>{
        if(text.length<2){
          setData({
              loading:false,
              results:[],
              error:false,
              message:""
          })
            return false;
        }
        setData({
            loading:true,
            results:[],
            error:false,
            message:""
        })
        let formData = new FormData();
        formData.append("search","search");
        formData.append("searchQuery",text);
        fetch(APIURL,{
            method:"POST",
            body:formData,
        }).then(req=>req.json()).then(res=>{
            setData({
                loading:false,
                results:res.data,
                error:res.error,
                message:res.message
            })
        }).catch(e=>{
            setData({
                loading:false,
                results:[],
                error:true,
                message:"An error occurred. Please try again."
            })
        })
    }
    useEffect(()=>{
        window.addEventListener('resize', ()=>{
            if(window.innerWidth > 800){
                setIsVisible(false);
            }
        });
    })

    return(
        <div className={"header"}>
            <SmallScreenSidebar isVisible={isVisible} setIsVisible={setIsVisible} />

            <Modal
                show={showModal}
                size="xl"
                onBackdropClick={()=>setShowModal(false)}
                onHide={()=>setShowModal(false)}
            >
                <Modal.Header
                    closeButton
                >
                    <Modal.Title>
                        Seller registration
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SellerRegistraion setShowModal={setShowModal} />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={()=>setShowModal(false)}
                        variant="primary"

                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="logo-and-hamburger">
                <Link to="/" className={"logo"}>savowarehouse.<label style={{
                    color:"var(--app-color)"
                }} >com</label></Link>

                <div
                    style={{
                        display:"flex",
                        flexDirection:"row",
                        gap:10,
                        alignItems:"center",
                        justifyContent:"center",
                    }}
                >
                    <Link 
                    className="hamburger"
                    to="/cart"
                    style={{
                        width:"fit-content",
                        height:"fit-content",
                        position:"relative"
                    }}
                    >
                        <label
                        style={{
                            display:"flex",
                            position:"absolute",
                            top:-16,
                            right:0,
                            color:"orange"
                        }}
                        >{total}</label>
                        <FaBus fill={"var(--app-color)"}  />
                    </Link>

                    <FiMenu 
                    className="hamburger" 
                    fill={"var(--app-color)"} 
                    color={"var(--app-color)"} 
                    onClick={()=>setIsVisible(isVisible?false:true)}
                    />
                </div>
            </div>
            
            <div
            style={{
                display:"flex",
                flexDirection:"row",
                height:"100%",
                alignItems:"center",
                justifyContent:"space-between",
                gap:15
            }}
            >
                <Link to="/" className="signin-signup"
                >
                    <FaHome color={activeTab=="/"?"#225ea3":"var(--app-color)"} size={23}/>
                </Link>

                <Link to="/deliveries" className="signin-signup"
                >
                    <label
                    style={{
                        display:"flex",
                        position:"absolute",
                        top:-18,
                        right:0,
                        color:activeTab=="/deliveries"?"var(--app-color)":"#225ea3",
                        fontWeight:"700"

                    }}
                    >{total}</label>
                    <FaBus color={activeTab=="/deliveries"?"#225ea3":"var(--app-color)"} />
                </Link>
                {
                    userData.loggedIn?
                    <>
                    

                    {/*<Link to="/profile/inbox" className="signin-signup">
                        <label
                        style={{
                            display:"flex",
                            position:"absolute",
                            top:-18,
                            right:0,
                            color:"orange"

                        }}
                        >0</label>
                        <FaInbox fill={"var(--app-color)"} />
                    </Link>*/}

                    <Link to="/orders" className="signin-signup">
                        <FaMap color={activeTab=="/orders"?"#225ea3":"var(--app-color)"} />
                    </Link>
                    <Link to="/profile" className="signin-signup">
                        <FaUser color={activeTab=="/profile"?"#225ea3":"var(--app-color)"} />
                    </Link>
                    
                    </>:
                    <Link to="/login" className="signin-signup">
                        Sign In | Sign Up
                    </Link>
                }
                

                

                {
                    userData.loggedIn?
                    <Link 
                    onClick={logout}
                    to="#" 
                    className="signin-signup">
                        <FiLogOut color={"red"} />
                    </Link>
                    :<></>
                }
                
            </div>
            
        </div>
    )
}

export default Header;