import React, { useContext, useEffect } from 'react';
import {Link,useLocation,useNavigate} from "react-router-dom";
import { CustomButton } from '../../../components';
import "./index.css";
import { FiEye, FiEyeOff, FiSend } from 'react-icons/fi';
import { UserContext } from '../../../contexts/UserContext';
import { useState } from 'react';
import { APIURL, APPDATAURLS } from '../../../utils/APPADATAURLS';
import { useCookies } from 'react-cookie';
import { Button, Modal } from 'react-bootstrap';
const LoginPage = (socket) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData,setUserData] = useContext(UserContext);
  const [credentials,setCredentials] = useState({
    email:"",
    password:"",
  });





  const [showModal,setShowModal] = useState(0);
  const [email,setEmail] = useState("");

  const [cookies,setCookies] = useCookies(["user"]);

  const [data,setData] = useState({
    results:[],
    error:false,
    loading:false,
    message:"",
  });


const [details,setDetails] = useState({
  error:false,
  message:"",
  data:[],
  loading:false
})
const forgotPassword = ()=>{
  setDetails({
    error:false,
  message:"",
  data:[],
  loading:true
  })
  let formData = new FormData();
      formData.append("delivery-forgot-password","forgot");
      formData.append("email",email);
      fetch(APIURL,{
        method:"POST",
        body:formData
      }).
      then(req=>req.json()).
      then(res=>{
        setDetails({
          error:res.error,
        message:res.message,
        data:res.data,
        loading:false
        })

        

        if(!res.error){
          setTimeout(() => {
            setShowModal(false);
          }, 3000);
        }
        
      }).catch(e=>{
        console.log(e);
        setDetails({
          error:true,
        message:"An error occured",
        data:[],
        loading:false
        })
      })
}


const [coordinates,setCoordinates] = useState({});
  const [errors,setErrors] = useState({
    state:false,
    message:""
  });
  const getUserLocation = ()=>{
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition((position)=>{
        setCoordinates(position.coords);
      
      },(error=>{
        setErrors({
          state:true,
          message:"An error occurred, try again later."
        })
        console.log(error);
      }),
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
     })
    }else{
      setErrors({
          state:true,
          message:"OOPS!! Location services not supported!"
        })
    }
  }



  const handleLogin = ()=>{
    if(credentials.email == ""){
      setData({
        error:true,
        loading:false,
        message:"Email is required!",
        data:[]
      });
      return;
    }

    if(credentials.password == ""){
      setData({
        error:true,
        loading:false,
        message:"Password is required!",
        data:[]
      });
      return;
    }

    setData({
        error:false,
        loading:true,
        message:"",
        data:[]
      });
    

    let formData = new FormData();
    formData.append("auth-delivery-guy","auth-delivery-guy");
    formData.append("email",credentials.email);
    formData.append("password",credentials.password);
    fetch(APIURL,{
      method:"POST",
      body:formData
    }).then(req=>req.json()).then(res=>{
      setData({
        error:res.error,
        loading:false,
        message:res.message,
        data:[]
      });
      if(!res.error){
        setUserData({loggedIn:true,data:res.data});
        
        if(coordinates.latitude){
          
          socket.emit("create",JSON.stringify({user:{...res.data,location:coordinates}}));
        }
        localStorage.setItem("delivery",JSON.stringify({loggedIn:true,data:res.data}));
        setCookies("delivery",JSON.stringify({loggedIn:true,data:res.data}));
        if(location.pathname == "/login"){
          navigate("/");
        }
      }
    }).catch(e=>{
      setData({
        error:true,
        loading:false,
        message:e.message,
        data:[]
      });
    })
    
    
  }

  const [isPassword,setIsPassword] = useState(true);
  
  useEffect(()=>{
    getUserLocation();
  },[]);
  return (
    <div
    className="body"
    >

    <Modal
      show={showModal}
      onBackdropClick={()=>setShowModal(false)}
      size='xl'
      onHide={()=>setShowModal(false)}
      >
        <Modal.Header closeButton >
          <Modal.Title>
            Forgot password?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
        style={{
          display:"flex",
          flexDirection:"column"
        }}
        >
          {
            details.error?
            <p style={{color:"red"}}>{details.message}</p>:
            !details.error?
            <p style={{color:"green"}}>{details.message}</p>:
            details.loading?
            <p>Requesting pasword reset....</p>:<></>
            
          }
          <label>Email</label>
          <input placeholder='Enter your email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
        </Modal.Body>
        <Modal.Footer>
          <Button
          variant='success'
          onClick={()=>{
            forgotPassword();  
          }}
          >
            Request password reset
          </Button>
        </Modal.Footer>
      </Modal>


      <div
      className="login_body"
      > 
        <img src={"/assets/images/savo_logo.jpg"}/>

        <div className="container" >
          <div
          className="login_credentials"
          >
            <Link
            to={"/"}
            className="website_name"
            >savowarehouse.
              <p style={{color:"var(--app-color)"}} >com</p>
            </Link>

            <p className="hello" >Hello!Make a delivery</p>
            <p className="login_into_account" >Log In to your Account!</p>
            <div
              className="credentials_container"
            >
              {
                data.error?
                <label
                style={{
                  color:"red"
                }}
                >{data.message}</label>:
                data.loading?
                <p>Loading</p>:
                <></>
              }
              <label>Email</label>
              <input 
              autoComplete='true'
              type="email" 
              onChange={(e)=>setCredentials({...credentials,email:e.target.value})} 
              placeholder="Email..."/>
              <label>Password</label>
              
                <div 
                style={{
                  position:"relative",
                  width:"100%"
                }}
                
                >
                  <input 
                  type={isPassword?"password":"text"}
                  onChange={(e)=>setCredentials({...credentials,password:e.target.value})} 
                  placeholder="Password..."/>
                  {
                    isPassword?
                    <FiEyeOff onClick={()=>setIsPassword(false)} size={25} style={{
                      position:"absolute",
                      right:10,
                      top:10
                    }} />:
                    <FiEye onClick={()=>setIsPassword(true)} size={25} style={{
                      position:"absolute",
                      right:10,
                      top:10
                    }} />
                  }

                </div>
              <div className="login_register" >
                <p style={{
                  color:"var(--app-color)",
                  cursor:"pointer"
                }} onClick={()=>setShowModal(true)}>
                  Forgot password?
                </p>
                <div className='login-btn'>
                  <CustomButton 
                  pressAction={handleLogin}
                  title={"Sign In"} 
                  borderRadius={20}
                  backgroundColor={"var(--app-color)"}
                  accessoryRight={<FiSend style={{marginLeft:10}} />}
                  />
                </div>
                
                {/*<p>
                  Dont have an account?
                  <Link className="link" to="/register" >Register here</Link>
                </p>*/}
              </div>

              <div
              style={{
                width:"100%",
                display:"flex",
                flexDirection:"row",
                marginTop:30
              }}
              >
                <input
                type="checkbox"
                style={{
                  width:20,
                  height:20,
                }}
                />
                <p>Remember me</p>
              </div>
              
              
            </div>

            
          </div>
          
        </div>

      </div>

    </div>
  )
}

export default LoginPage