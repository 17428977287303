import React,{useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import { FaCar } from "react-icons/fa"
import "./index.css";
import { APPDATAURLS, BASEURL } from '../../utils/APPADATAURLS';
import { HomeCategoriesLoading } from '../Loading';
const SideBar = ({setIvisible})=> {

    const [categories,setCategories] = useState({
        data:[],
        loading:false,
        error:false,
        message:""
    });

    const [imageLoaded,setImageLoaded] = useState(false);

    const fetchCategories = ()=>{
        setCategories({
        data:[],
        loading:true,
        error:false,
        message:""
        });
        fetch(APPDATAURLS.categories,{
        method:"get"
        }).then(req=>req.json()).then(response=>{
        setCategories({
        data:response.data,
        loading:false,
        error:response.error,
        message:response.message
        });
        }).catch(e=>{
        console.log(e);
        setCategories({
            data:[],
            loading:false,
            error:true,
            message:"An error occurred. Try again later"
        });
        })
    }
useEffect(()=>{
//fetchCategories();
},[])
  return (
    <div 
        className='sidebar'
        >
        {
        categories.error?<div></div>:
        categories.loading?<HomeCategoriesLoading/>:
        
        categories.data.map((category,index)=>{
            return(
                <Link 
                    onClick={()=>setIvisible(false)}
                    key={index}
                    to={`/shop/${category.categoryName}/${category.id}`} 
                    className={"category-link"}
                    >
                    <img 
                    onLoad={()=>setImageLoaded(true)}
                    onError={()=>setImageLoaded(false)}
                    style={{
                        display:imageLoaded?"flex":"flex",
                    }} 
                    className='star' 
                    src={`${BASEURL}/${category.categoryImage}`} />  
                    <img style={{
                        display:imageLoaded?"none":"none",
                    }} className='star' src={"/assets/images/savo_logo.jpg"} />  
                    {category.categoryName}
                </Link>
            )
        })}
        
        
    </div>
  )
}

export default SideBar;