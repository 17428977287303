import React, { useCallback, useEffect, useState,useContext } from 'react'
import { useParams } from 'react-router-dom';
import { MapContainer, Marker, Popup, TileLayer, Tooltip, useMap } from 'react-leaflet';
import { FaMapPin } from 'react-icons/fa';
import iconMarker from 'leaflet/dist/images/marker-icon.png'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
//import 'leaflet-routing-machine/dist/leaflet-routing-machine.js';
//import "leaflet-routing-machine";
import { BottomTabs, Header, SmallScreenHeader } from '../../components';
import "./addresses.css";
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { APPDATAURLS, GOOGLEMAPSAPIKEY } from '../../utils/APPADATAURLS';
import { UserContext } from '../../contexts';
import { LoginPage } from '../Auth';
const icon = L.icon({ 
  iconUrl: iconMarker, 
});


const DeliveriesOnMap = () => {

  const pickupPoints = [
    {
      productName:"Product 1",
      companyName:"Company 1",
      latitude:"-1.20342350000",
      longitude:"36.85656000"
    },
    {
      productName:"Product 2",
      companyName:"Company 2",
      latitude:"-1.18342350000",
      longitude:"36.93656000"
    },
    {
      productName:"Product 3",
      companyName:"Company 3",
      latitude:"-1.25342350000",
      longitude:"36.91656000"
    }
  ];


  const customer = {
    name:"Customer Name",
    Area:"Area 1",
    latitude:"-1.24342350000",
    longitude:"36.91656000"
  };

const [userData,setUserData] = useContext(UserContext);

const [map,setMap] = useState(null);
const {isLoaded} = useJsApiLoader({
  id:"google-map-script",
  googleMapsApiKey:GOOGLEMAPSAPIKEY
}); 

const [directionsService,setDirectionsService] = useState(null);
const [directionsDisplay,setDirectionsDisplay] = useState(null);
const [isUpdated,setIsUpdated] = useState(false);

useEffect(()=>{
  setDirectionsDisplay(null);
  if(map){
    setDirectionsService(new window.google.maps.DirectionsService());
    setDirectionsDisplay(new window.google.maps.DirectionsRenderer());
  }

  if(directionsDisplay){
    directionsDisplay.setMap(map);
    directionsDisplay.setOptions({suppressMarkers:true});
  }
  
},[map,isUpdated]);

const showDirection = (lat,lng)=>{
  setIsUpdated(!isUpdated);
  var haight = new window.google.maps.LatLng(coordinates[0], coordinates[1]);
    var oceanBeach = new window.google.maps.LatLng(parseFloat(lat),parseFloat(lng));
    var mapOptions = {
      zoom: 14,
      center: haight
    }
    //setDirectionsDisplay(new window.google.maps.DirectionsRenderer());
    var request = {
      origin: haight,
      destination: oceanBeach,
      // Note that JavaScript allows us to access the constant
      // using square brackets and a string value as its
      // "property."
      travelMode: window.google.maps.TravelMode["DRIVING"]
  };
  
  directionsService.route(request, function(response, status) {
    if (status == 'OK') {
      directionsDisplay.setDirections(response);
    }
  });
}

const [coordinates,setCoordinates] = useState([0,0]);
  const [errors,setErrors] = useState({
    state:false,
    message:""
  });
  const getUserLocation = ()=>{
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition((position)=>{
        setCoordinates([position.coords.latitude,position.coords.longitude]);
       
      },(error=>{
        setErrors({
          state:true,
          message:"An error occurred, try again later."
        })
        console.log(error);
      }),
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
     })
    }else{
      setErrors({
          state:true,
          message:"OOPS!! Location services not supported!"
        })
    }
  }

  const [deliveries,setDeliveries] = useState({
    error:false,
    loading:false,
    message:"",
    results:null
  });
  const fetchDeliveries = ()=>{
    fetch(`${APPDATAURLS.getDeliveryGuyDeliveries}${userData.data.id}`,{
        method:"GET"
    })
    .then(req=>req.json())
    .then(response=>{
        setDeliveries({...deliveries,results:response.data});
        console.log(response);
    }).catch(err=>{
        console.log(err);
    });
}

  useEffect(()=>{
    getUserLocation();
    fetchDeliveries();
  },[]);


  return (
    <>
      {
        userData.loggedIn?<div className='body' >
        <Header/>
        <div className='addresses-wrapper' >
            <SmallScreenHeader backButtonShown={false} title={"Locations of different items."} />
            <div className='map' >
            {
            isLoaded && coordinates[0] && deliveries.results?
            <GoogleMap
            mapContainerStyle={{
              width:"100%",
              height:"100%"
            }}
            zoom={12}

            // center={{lat:coordinates[0],lng:coordinates[1]}}
            center={{lat:coordinates[0],lng:coordinates[1]}}
            onLoad={(map)=>{
              const bounds = new window.google.maps.LatLngBounds({lat:coordinates[0],lng:coordinates[1]});
              //map.fitBounds(bounds);
              setMap(map);
            }}
            onUnmount={(map)=>{
                setMap(null);
            }}
            >

              <MarkerF 
                position={{lat:parseFloat(customer.latitude),lng:parseFloat(customer.longitude)}} 
                title={customer.name}
                label={customer.name}
                onClick={(e)=>{    
                  showDirection(customer.latitude,customer.longitude);
                }}
                />

                
              <MarkerF 
                position={{lat:coordinates[0],lng:coordinates[1]}} 
                title='Me'
                label={"Me"}
                
                />


                  {
            deliveries.results.map((item,index)=>{
                return item.products.map((product,i)=>{
                  return <MarkerF 
                            position={{lat:parseFloat(product.latitude),lng:parseFloat(product.longitude)}}
                            key={i}
                            label={product.companyName}
                            title={product.companyName}
                            onClick={(e)=>{
                              
                              showDirection(product.latitude,product.longitude);
                            }}
                            >
                    </MarkerF>
                })
            })
          }
            
            </GoogleMap>
        :
        <MapContainer 
            center={coordinates} 
            zoom={13} 
            scrollWheelZoom={false} 
            style={{width:"100%",height:"100%",zIndex:1}}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {
            pickupPoints.map((item,index)=>{
                return <Marker position={[parseFloat(item.latitude),parseFloat(item.longitude)]} icon={icon} key={index}>
                        <Tooltip>{item.companyName}</Tooltip>
                        <Popup>{item.productName}</Popup>
                    </Marker>
            })
          }

          
            
            {/*<RoutingLayer routes={pickupPoints}/>*/}
          </MapContainer>
          } 

        {/* <MapContainer 
            center={coordinates} 
            zoom={13} 
            scrollWheelZoom={false} 
            style={{width:"100%",height:"100%",zIndex:1}}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {
            pickupPoints.map((item,index)=>{
                return <Marker position={[parseFloat(item.latitude),parseFloat(item.longitude)]} icon={icon} key={index}>
                        <Tooltip>{item.companyName}</Tooltip>
                        <Popup>{item.productName}</Popup>
                    </Marker>
            })
          }

          
            
            <RoutingLayer routes={pickupPoints}/>
          </MapContainer> */}
        
        </div>
        </div>
      
        <BottomTabs/>
    </div>:
    <LoginPage/>
      }
    </>
  )
}


const RoutingLayer = ({routes})=>{
  const map = useMap();
  const [routing,setRouting] = useState([]);

  const getRoute = async=>{
    let output =[];
    for (let index = 0; index < routes.length; index++) {
      output.push(L.LatLng(parseFloat(routes[index].latitude),parseFloat(routes[index].longitude)))
    }
    return output;
  }

  useEffect(()=>{
    const temp = getRoute();
    setRouting(temp);
    const mapRouting = new L.Routing.control({
      plan:L.Routing.OSRMv1(routing,{
        createMarker:function(){return false}
      }),
      autoRoute:true,
      show:false,
      fitSelectedRoutes:true,
    }).addTo(map)
  },[])
}

export default DeliveriesOnMap;